import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

const CvPage = ({ data }: { data: GatsbyTypes.CvPageQueryQuery }) => (
  <Layout>
    <SEO title="CV" />
    <article className="prose">
      {data?.prismicCvpage?.data?.content?.raw && (
        <RichText render={data?.prismicCvpage?.data?.content?.raw} />
      )}
    </article>
  </Layout>
);

export const query = graphql`
  query CvPageQuery {
    prismicCvpage {
      data {
        content {
          raw
        }
      }
    }
  }
`;

export default CvPage;
